import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import VideoIcon from "../../images/video.svg";
import CubeIcon from "../../images/cube.svg";
import HandPointUpIcon from "../../images/hand-point-up.svg";
import CarIcon from "../../images/car.svg";
import WalkingIcon from "../../images/walking.svg";
import MapIcon from "../../images/map.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: VideoIcon,
      title: "3D Animations",
      description: "Impress your clients with an unparalleled level of detail in a 3D animation."
    },
    {
      imageSrc: CubeIcon,
      title: "Product Visualizations",
      description: "Ensure that your work is easier to grasp through a visualization of your product."
    },
    {
      imageSrc: HandPointUpIcon,
      title: "Interactive Experiences",
      description: "Communicate with your clients, business partners, or the public through an interactive experience."
    },
    {
      imageSrc: CarIcon,
      title: "Traffic Simulation Visualizations",
      description: "Set yourself apart from the competition in your next client or public outreach meeting."
    },
    {
      imageSrc: WalkingIcon,
      title: "Pedestrian Simulation Visualizations",
      description: "Breathe life into your pedestrian simulations."
    },
    {
      imageSrc: MapIcon,
      title: "Interactive 3D Maps",
      description: "Find your way in a new way with an interactive 3D map."
    }
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>Our Professional Services</Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
